import { Box, Typography } from "@mui/material";
import * as React from "react";
import Markdown from "./Markdown";

function TitleWithActions({ title, children, useMarkdown = false }) {
  const style = styles();

  return (
    <Box sx={style.mainContainer}>
      <Box sx={{ display: "flex", flex: 1}}>
        {useMarkdown ? (
          <Markdown content={`##${title}`}></Markdown>
        ) : (
          <Typography sx={{ display: "inline-block", fontWeight: 'bold' }} variant="h5">
            {title}
          </Typography>
        )}
      </Box>

      <Box sx={{ display: "flex", marginLeft: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default TitleWithActions;

const styles = () => ({
  mainContainer: {
    width: "100%",
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    position: "sticky",
    flexWrap: "wrap",
    gap: "15px"
  },
});

import GeneratedYearlyContentPlanning from "../../app/GeneratedYearlyContentPlanning";

export default class GeneratedYearlyContentPlanningAdapter {
  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  adapt() {
    const yearlyContentPlanning = this._jsonResponse.yearly_content_planning;
    return new GeneratedYearlyContentPlanning(
      yearlyContentPlanning.id,
      yearlyContentPlanning.title,
      yearlyContentPlanning.content,
      yearlyContentPlanning.subject,
      yearlyContentPlanning.grade,
      yearlyContentPlanning.region,
      yearlyContentPlanning.context,
      yearlyContentPlanning.public_id,
      yearlyContentPlanning.app_version,
      yearlyContentPlanning.created_at,
    );
  }
}

import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  BoldItalicUnderlineToggles,
  headingsPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  Separator,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import "./ContentPlanningEditor.css";
import FilledTextButton from "../buttons/FilledTextButton";
import { fetchAndUpdateStateWithResult } from "../../utils";
import { useApplication } from "../../providers/applicationProvider";

function ContentPlanningEditor({ contentPlanning, onContentPlanningChange }) {
  const [editedContent, setEditedContent] = useState("");
  const [isSavingContent, setSavingContent] = useState(false);

  const editedContentRef = useRef();

  const application = useApplication();

  const isContentPlanningDefined = () => contentPlanning !== undefined;
  const style = styles();

  useEffect(() => {
    if (isContentPlanningDefined()) {
      const content = contentPlanning.content();
      console.log(contentPlanning);
      editedContentRef.current.setMarkdown(content);
      setEditedContent(content);
    }
  }, [contentPlanning]);

  const saveEditedContent = () => {
    fetchAndUpdateStateWithResult(
      application
        .apiClient()
        .updateContentPlanningContent(contentPlanning.id(), editedContent)
        .then((response) => {
          const contentPlanning = response.contentPlanning();
          onContentPlanningChange(contentPlanning);
        }),
      setSavingContent,
      () => {},
    );
  };

  return (
    <Box sx={style.editor}>
      <MDXEditor
        contentEditableClassName="editor-content"
        ref={editedContentRef}
        markdown={editedContent}
        onChange={setEditedContent}
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <Box sx={style.editorToolbar}>
                <Box sx={style.editionOptions}>
                  <UndoRedo />
                  <Separator />
                  <BoldItalicUnderlineToggles />
                  <Separator />
                  <ListsToggle />
                </Box>
                <Box sx={style.saveButton}>
                  <FilledTextButton
                    text={
                      isSavingContent
                        ? "Guardando cambios..."
                        : "Guardar cambios"
                    }
                    onClick={saveEditedContent}
                    customStyles={{ padding: 0 }}
                    disabled={isSavingContent}
                  />
                </Box>
              </Box>
            ),
          }),
        ]}
      />
    </Box>
  );
}

export default ContentPlanningEditor;

const styles = () => ({
  editorToolbar: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    justifyContent: "space-between",
    width: "100%",
  },
  editionOptions: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  saveButton: {
    marginLeft: "auto",
  },
  editor: {
    paddingTop: "8px",
  },
});

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplication } from '../providers/applicationProvider';
import DrawerPanel from '../components/panels/DrawerPanel';
import TitleWithActions from '../components/TitleWithActions';
import LoadableContent from '../components/LoadableContent';
import SeeContentPlanningAndPrompt from '../components/SeeContentPlanningAndPrompt';
import FramedContainer from '../components/framed-container/FramedContainer';
import useIsOnMobile from '../hooks/useIsOnMobile';
import IconButton from '../components/buttons/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function ContentPlanningHistory () {
  const [selectedContentPlanning, setSelectedContentPlanning] =
    useState(undefined);
  const [isFetchingHistory, setFetchingHistory] = useState(true);
  const [contentPlanningHistory, setContentPlanningHistory] = useState([]);

  const application = useApplication();
  const navigate = useNavigate();
  const apiClient = application.apiClient();
  const theme = useTheme();
  const style = styles(theme);
  const isOnMobile = useIsOnMobile();

  useEffect(() => {
    const getContentPlanningHistory = () => {
      setFetchingHistory(true);
      apiClient
        .contentPlanningHistory()
        .then((response) => {
          setFetchingHistory(false);
          setContentPlanningHistory(response.contentPlannings());
        })
        .catch(() => {
          setFetchingHistory(false);
          setContentPlanningHistory([]);
        });
    };

    getContentPlanningHistory();
  }, [navigate, apiClient]);

  const hasSelectedContentPlanning = () => {
    return selectedContentPlanning !== undefined;
  };

  const selectedContentPlanningId = () => {
    return hasSelectedContentPlanning()
      ? selectedContentPlanning.id()
      : undefined;
  };

  const unselectContentPlanning = () => {
    setSelectedContentPlanning(undefined);
  }

  const renderContentPlanningList = () => {
    if (isOnMobile && hasSelectedContentPlanning()) {
      return null;
    }

    return (
      <Box sx={style.contentPlanningListContainer}>
        <List sx={style.contentPlanningList}>
          {contentPlanningHistory.map((contentPlanning) => {
            const id = contentPlanning.id();
            const title = contentPlanning.subject();
            const publicId = contentPlanning.publicId().toUpperCase();
            const subTitle = contentPlanning.creationDate();
            return (
              <ListItem key={id} disablePadding sx={style.contentPlanningListItem}>
                <ListItemButton
                  selected={selectedContentPlanningId() === id}
                  onClick={() => setSelectedContentPlanning(contentPlanning)}
                >
                  {publicId ? (
                    <ListItemText
                      primary={`${publicId} - ${title}`}
                      secondary={subTitle}
                    />
                  ) : (
                    <ListItemText primary={title} secondary={subTitle}/>
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  const renderSelectedContentPlanning = () => {
    if (hasSelectedContentPlanning()) {
      return (
        <Box sx={style.contentPlanningContainer}>
          {isOnMobile && (
            <Box sx={style.closeContentPlanningContainer}>
              <IconButton icon={CloseIcon} onClick={unselectContentPlanning}></IconButton>
            </Box>
          )}
          <Box sx={style.contentContainer}>
            <SeeContentPlanningAndPrompt
              contentPlanning={selectedContentPlanning}
            />
          </Box>
        </Box>
      );
    }
  };

  return (
    <DrawerPanel>
      <TitleWithActions title="Historial"/>
      <LoadableContent isLoading={isFetchingHistory}>
        <FramedContainer styles={style.framedContainer}>
          <Box sx={style.containerBox}>
            {renderContentPlanningList()}
            {renderSelectedContentPlanning()}
          </Box>
        </FramedContainer>
      </LoadableContent>
    </DrawerPanel>
  );
}

export default ContentPlanningHistory;

const styles = (theme) => ({
  containerBox: {
    display: 'flex',
    flexDirection: 'row'
  },
  contentPlanningListContainer: {
    display: 'flex',
    flexGrow: 1
  },
  contentPlanningList: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      flexGrow: 1,
      borderRight: 1,
      borderColor: 'divider',
      overflowY: 'scroll',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexGrow: 1,
    }
  },
  contentPlanningListItem: {
    display: 'flex',
    flexGrow: 1,
  },
  contentPlanningContainer: {
    flex: 7,
    display: 'flex',
    flexDirection: 'column'
  },
  closeContentPlanningContainer: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  framedContainer: {
    [theme.breakpoints.down('md')]: {
      padding: '10px'
    }
  },
  contentContainer: {
    overflowY: 'scroll'
  }
});

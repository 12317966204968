import './App.css';
import React, { useState } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import TableChartIcon from '@mui/icons-material/TableChart';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CreateContentPlanning from './screens/CreateContentPlanning';
import ContentArea from './components/ContentArea';
import ContextDetail from './screens/ContextDetail';
import ContentPlanningHistory from './screens/ContentPlanningHistory';
import { useApplication } from './providers/applicationProvider';
import CreateYearlyContentPlanning from './screens/CreateYearlyContentPlanning';
import useIsOnMobile from './hooks/useIsOnMobile';
import EducationAppBar from './components/EducationAppBar';

function App () {
  const application = useApplication();
  const theme = useTheme();
  const isOnMobileMode = useIsOnMobile();
  const drawerVariant = isOnMobileMode ? 'temporary' : 'permanent';
  const [drawerOpen, setDrawerOpen] = useState(!isOnMobileMode);
  const DRAWER_ITEMS = {
    CONTENT_PLANNING: 'Planificación',
    YEARLY_CONTENT_PLANNING: 'Planificación anual', // TODO: Check with Uxer where shall we place the yearly content planning
    CONTENT_PLANNING_HISTORY: 'Historial',
    CONTEXT: 'Fuentes',
    LOG_OUT: 'Cerrar sesión',
  };

  const style = styles(theme);

  const [selectedItem, setSelectedItem] = useState(
    DRAWER_ITEMS.CONTENT_PLANNING,
  );

  const renderDrawerItem = (text, icon, onClick, sendToBottom) => {
    const isSelected = selectedItem === text;
    return (
      <ListItem
        key={text}
        disablePadding
        selected={isSelected}
        onClick={() => {
          setSelectedItem(text);
          onClick?.();
          setDrawerOpen(false);
        }}
        sx={{
          marginTop: sendToBottom ? 'auto' : 0,
          ...style.listItemContainer,
        }}
      >
        <ListItemButton sx={style.listItemButton} selected={isSelected}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={<Box fontWeight={isSelected ? '800' : 'auto'}>{text}</Box>}/>
        </ListItemButton>
      </ListItem>
    );
  };

  const renderSelectedContent = () => {
    switch (selectedItem) {
      case DRAWER_ITEMS.CONTEXT:
        return <ContextDetail/>;
      case DRAWER_ITEMS.CONTENT_PLANNING_HISTORY:
        return <ContentPlanningHistory/>;
      case DRAWER_ITEMS.YEARLY_CONTENT_PLANNING:
        return <CreateYearlyContentPlanning/>;
      case DRAWER_ITEMS.CONTENT_PLANNING:
      default:
        return <CreateContentPlanning/>;
    }
  };

  return (
    <Box sx={style.mainContainer}>
      <EducationAppBar onMenuIconPress={() => {setDrawerOpen(!drawerOpen);}} showMenuIcon showTitle={false} />
      <Drawer
        variant={drawerVariant}
        sx={style.drawerStyle}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Typography variant="h6" noWrap component="div" sx={style.title}>
          Educación Argentina
        </Typography>
        <List sx={style.listContainer}>
          {renderDrawerItem(DRAWER_ITEMS.CONTENT_PLANNING, <TableChartIcon/>)}
          {renderDrawerItem(DRAWER_ITEMS.YEARLY_CONTENT_PLANNING, <TableChartIcon/>)}
          {renderDrawerItem(
            DRAWER_ITEMS.CONTENT_PLANNING_HISTORY,
            <HistoryIcon/>,
          )}
          {renderDrawerItem(DRAWER_ITEMS.CONTEXT, <TextSnippetIcon/>)}
          {renderDrawerItem(
            DRAWER_ITEMS.LOG_OUT,
            <LogoutIcon/>,
            application.logOut,
            true,)}
        </List>
      </Drawer>
      <ContentArea>{renderSelectedContent()}</ContentArea>
    </Box>
  );
}

export default App;

const styles = (theme) => ({
  mainContainer: {
    display: "flex",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    padding: theme.spacing(2),
  },
  drawerStyle: {
    width: 240,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
    zIndex: (theme) => theme.zIndex.drawer
  },
  listContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: 0
  },
  listItemContainer: {
    display: 'block',
  },
  listItemButton: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
      fontWeight: '800',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
});

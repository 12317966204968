import GeneratedContentPlanning from "../../app/GeneratedContentPlanning";

export default class GeneratedContentPlanningAdapter {
  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  adapt() {
    // TODO missing info in generation endpoint
    const contentPlanning = this._jsonResponse.content_planning;
    return new GeneratedContentPlanning(
      contentPlanning.id,
      undefined,
      undefined,
      undefined,
      undefined,
      contentPlanning.content,
      undefined,
      undefined,
      undefined,
      undefined,
      contentPlanning.context_files_names,
      contentPlanning.public_id,
      contentPlanning.title
    );
  }
}

import GeneratedContentPlanning from './generated-content-planning/GeneratedContentPlanning';
import GeneratedEvaluationsDetail from './content-planning-history/GeneratedEvaluationsDetail';
import GeneratedActivitiesDetail from './content-planning-history/GeneratedActivitiesDetail';
import GeneratedFeedbackDetail from './content-planning-history/GeneratedFeedbackDetail';
import React from 'react';
import { getUrlForContentPlanningWith } from '../utils';
import SeeGeneratedContentPlanningWithPromptWrapper
  from './generated-content-planning/SeeGeneratedContentPlanningWithPromptWrapper';

export default function SeeContentPlanningAndPrompt({
  contentPlanning,
  editable,
}) {
  const parameters = {
    'Región': contentPlanning.region(),
    'Grado': contentPlanning.grade(),
    'Extensión': contentPlanning.timeUnit(),
    'Tema': contentPlanning.topic(),
    'Contexto': contentPlanning.instruction(),
  };


  return (
    <SeeGeneratedContentPlanningWithPromptWrapper
      contentPlanning={contentPlanning}
      parameters={parameters}
    >
      <GeneratedContentPlanning
        contentPlanning={contentPlanning}
        readOnly
        getPublicUrlFor={(publicId) => getUrlForContentPlanningWith(publicId)}
        editable={editable}
      />
      <GeneratedEvaluationsDetail contentPlanning={contentPlanning} />
      <GeneratedActivitiesDetail contentPlanning={contentPlanning} />
      <GeneratedFeedbackDetail contentPlanning={contentPlanning} />
    </SeeGeneratedContentPlanningWithPromptWrapper>
  );
}

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApplication } from "../providers/applicationProvider";
import LoadableContent from "../components/LoadableContent";
import { Box } from "@mui/material";
import SeeYearlyContentPlanningAndPrompt from "../components/SeeYearlyContentPlanningAndPrompt";

// TODO: Repeated code with SeeContentPlanning

export default function SeeYearlyContentPlanning() {
  const publicId = useParams().publicId;
  const application = useApplication();
  const [yearlyContentPlanning, setYearlyContentPlanning] = useState();

  const style = styles();

  useEffect(() => {
    application
      .apiClient()
      .getYearlyContentPlanningByPublicId(publicId)
      .then((response) => {
        setYearlyContentPlanning(response.yearlyContentPlanning());
      });
  }, []);

  return (
    <Box sx={style.mainContainer}>
      <LoadableContent isLoading={!yearlyContentPlanning}>
        <Box style={style.contentPlanningContainer}>
          <SeeYearlyContentPlanningAndPrompt
            yearlyContentPlanning={yearlyContentPlanning}
          />
        </Box>
      </LoadableContent>
    </Box>
  );
}

const styles = () => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  contentPlanningContainer: {
    marginTop: "30px",
    width: "80%",
  },
});

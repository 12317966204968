import { Box } from "@mui/material";
import errorToast from "../toasts/ErrorToast";
import { useState } from "react";
import TextArea from "../fields/TextArea";
import { useApplication } from "../../providers/applicationProvider";
import FilledTextButton from "../buttons/FilledTextButton";
import LoadableContent from "../LoadableContent";

export default function ContentPlanningRegenerationForm({
                                                            contentPlanningToRegenerate,
                                                            onContentPlanningRegenerated,
                                                            isYearlyContentPlanning = false
                                                        }){
    const application = useApplication();
    const [regenerationInstruction, setRegenerationInstruction] = useState("");
    const [isRetrievingRegeneration, setIsRetrievingRegeneration] =
        useState(false);
    const style = styles();

    function regenerateContentPlanning() {
        setIsRetrievingRegeneration(true);
        _endpointToRegenerateContentPlanning()
        .then((response) => {
          const regeneratedContentPlanning = _extractContentPlanningContentFrom(response);
            onContentPlanningRegenerated(regeneratedContentPlanning);
        })
        .catch(() => {
          errorToast("Ocurrió un problema regenerar la planificación.");
        })
        .finally(() => {
          setIsRetrievingRegeneration(false);
        });
    }

    const _isSubmitButtonDisabled = () => {
        return regenerationInstruction === "";
    };

    function _endpointToRegenerateContentPlanning () {
        if (isYearlyContentPlanning) {
            return application.apiClient().regenerateYearlyContentPlanning(contentPlanningToRegenerate.id(), regenerationInstruction);
        }
        return application.apiClient().regenerateContentPlanning(contentPlanningToRegenerate.id(), regenerationInstruction);
    }

    const _renderRegenerationForm = () => {
        return (
            <Box sx={style.mainContainer} component="form">
                <LoadableContent
                    isLoading={isRetrievingRegeneration}
                    text={"Regenerando planificación..."}
                >
                    <TextArea
                        id="feedback"
                        value={regenerationInstruction}
                        onChange={(value) => setRegenerationInstruction(value)}
                        placeholder="Indicanos qué puede ser mejorado."
                        disabled={false}
                    />
                    <FilledTextButton text={"regenerar"} onClick={regenerateContentPlanning} disabled={_isSubmitButtonDisabled()}/>
                </LoadableContent>
            </Box>
        );
    };

    function _extractContentPlanningContentFrom(response) {
        if (isYearlyContentPlanning) {
            return response.yearlyContentPlanning();
        }
        return response.contentPlanning();
    }

    function _renderFormIfThereIsA(contentPlanningToRegenerate) {
        return contentPlanningToRegenerate ? _renderRegenerationForm() : undefined;
    }

    return _renderFormIfThereIsA(contentPlanningToRegenerate);
}


const styles = () => ({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
});

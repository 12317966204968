import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";
import FramedContainer from "../framed-container/FramedContainer";
import React, { useRef, useState } from "react";
import Markdown from "../Markdown";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import TabPanel from "../panels/TabPanel";
import successToast from "../toasts/SuccessToast";
import { ContentPlanningPublicId } from "../buttons/ContentPlanningPublicId";
import FeedbackModal from "../modals/FeedbackModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClipboard } from "../../utils";
import errorToast from "../toasts/ErrorToast";
import FilledIconButton from "../buttons/FilledIconButton";
import TitleWithActions from "../TitleWithActions";
import ContentPlanningRegenerationForm from "../forms/ContentPlanningRegenerationForm";

function GeneratedYearlyContentPlanning({
  yearlyContentPlanning,
  readOnly,
  onYearlyContentPlanningChange,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [wasFeedbackSubmitted, setWasFeedbackSubmitted] = useState(false);
  const markdownRef = useRef();

  const isYearlyContentPlanningDefined = () =>
    yearlyContentPlanning !== undefined;
  const style = styles();

  const renderFeedbackForm = () => {
    if (!readOnly) {
      return (
        <FeedbackModal
          contentPlanningId={yearlyContentPlanning.id()}
          onFeedbackSubmitted={() => {
            setWasFeedbackSubmitted(true);
          }}
          wasFeedbackSubmitted={wasFeedbackSubmitted}
        />
      );
    }
    return <></>;
  };

  const renderContextFiles = () => {
    if (yearlyContentPlanning.hasContextFiles()) {
      const contextFiles = yearlyContentPlanning.contextFiles();
      return contextFiles.length === 0 ? (
        "No se utilizaron documentos para generar el contenido."
      ) : (
        <List>
          {contextFiles.map((source) => (
            <ListItem key={source}>
              <ListItemIcon>
                <TextSnippetIcon />
              </ListItemIcon>
              <ListItemText primary={source} />
            </ListItem>
          ))}
        </List>
      );
    }
  };

  const onContentPlanningRegenerated = (yearlyContentPlanning) => {
    onYearlyContentPlanningChange(yearlyContentPlanning);
    setWasFeedbackSubmitted(false);
    successToast("Planificación anual regenerada.");
  };

  const actionButtons = () => {
    return (
      <Box sx={style.rowContainer}>
        <FilledIconButton
          icon={ContentCopyIcon}
          label={"Copiar planificación"}
          onClick={() =>
            copyToClipboard(
              `${yearlyContentPlanning.title()} \n\n${markdownRef.current.innerText}`,
              () => successToast("Planificación copiada"),
              () => errorToast("No se pudo copiar la planificación."),
            )
          }
        />
      </Box>
    );
  };

  const renderYearlyContentPlanning = () => (
    <FramedContainer styles={style.mainContainer}>
      <TitleWithActions
        title={yearlyContentPlanning.title()}
        useMarkdown={true}
      />
      <Box sx={style.rowContainer}>
        <ContentPlanningPublicId publicId={yearlyContentPlanning.publicId()} />
        {actionButtons()}
      </Box>
      <Box sx={{ width: "100%" }} ref={markdownRef}>
        <Markdown content={yearlyContentPlanning.content()}></Markdown>
      </Box>

      <Box sx={{ paddingTop: "20px" }}>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          aria-label="Detalle del contenido generado"
        >
          <Tab label="Documentos contexto" />
          {!readOnly && <Tab label="Regeneración" />}
        </Tabs>
      </Box>

      <TabPanel selectedIndex={selectedTab} tabIndex={0}>
        {renderContextFiles()}
      </TabPanel>
      {!readOnly && (
        <TabPanel selectedIndex={selectedTab} tabIndex={1}>
          <ContentPlanningRegenerationForm
            contentPlanningToRegenerate={yearlyContentPlanning}
            onContentPlanningRegenerated={onContentPlanningRegenerated}
            isYearlyContentPlanning={true}
          />
        </TabPanel>
      )}
      {renderFeedbackForm()}
    </FramedContainer>
  );

  return isYearlyContentPlanningDefined()
    ? renderYearlyContentPlanning()
    : undefined;
}

export default GeneratedYearlyContentPlanning;

const styles = () => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
  },
});

import { AppBar, Toolbar, Typography, useTheme } from '@mui/material';
import AppVersion from './AppVersion';
import React from 'react';
import { useApplication } from '../providers/applicationProvider';
import { observer } from 'mobx-react';
import IconButton from './buttons/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

function EducationAppBar({onMenuIconPress, showMenuIcon = false, showTitle = true}) {
  const application = useApplication();
  const theme = useTheme();
  const style = styles(theme);

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.appBar, background:'white'}}
    >
      <Toolbar sx={style.toolbarContainer}>
        {showMenuIcon && <IconButton icon={MenuIcon} onClick={onMenuIconPress} iconColor={theme.palette.primary.main}/>}
        {showTitle && <Typography variant="h6" noWrap component="div" sx={style.title}>
          Educación Argentina
        </Typography>}
        <AppVersion version={application.currentVersion} />
      </Toolbar>
    </AppBar>
  );
}

export default observer(EducationAppBar);

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  toolbarContainer: {
    display: "flex",
    justifyContent: "space-between",
  }
});

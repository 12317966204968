import { Divider, Typography } from "@mui/material";
import ParameterDetail from "./content-planning-history/ParameterDetail";
import React from "react";

export default function ParametersBox({ parameters }) {
  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6" gutterBottom>
        Parámetros:
      </Typography>
      {Object.keys(parameters).map((key) => (
        <ParameterDetail title={key} description={parameters[key]} />
      ))}
      <Divider sx={{ my: 2 }} />
    </>
  );
}

import ContentPlanningAdapter from "./ContentPlanningAdapter";

export default class ContentPlanningsAdapter {
  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  adapt() {
    const contentPlannings = this._jsonResponse.content_plannings;
    return contentPlannings.map((contentPlanning) =>
      new ContentPlanningAdapter(contentPlanning).adapt(),
    );
  }
}

export default class GeneratedYearlyContentPlanning {
  constructor(
    id,
    title,
    content,
    subject,
    grade,
    region,
    context,
    publicId,
    appVersion,
    creationDate,
  ) {
    this._id = id;
    this._content = content;
    this._title = title;
    this._subject = subject;
    this._grade = grade;
    this._region = region;
    this._context = context;
    this._publicId = publicId;
    this._appVersion = appVersion;
    this._creationDate = creationDate;
  }

  id() {
    return this._id;
  }

  content() {
    return this._content;
  }

  title() {
    return this._title;
  }

  subject() {
    return this._subject;
  }

  grade() {
    return this._grade;
  }

  region() {
    return this._region;
  }

  context() {
    return this._context;
  }

  publicId() {
    return this._publicId;
  }

  hasContextFiles() {
    return false;
  }

  appVersion() {
    return this._appVersion;
  }

  creationDate() {
    return this._creationDate;
  }
}

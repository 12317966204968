import { Box, Typography } from "@mui/material";
import React from "react";
import ParametersBox from "../ParametersBox";
import TitleWithActions from "../TitleWithActions";
import PdfExportableContainer from "../PdfExportableContainer";
import ContentPlanningVersionTag from "../ContentPlanningVersionTag";

export default function SeeGeneratedContentPlanningWithPromptWrapper({
  contentPlanning,
  children,
  parameters,
}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <PdfExportableContainer
        buttonTitle="Descargar PDF"
        fileName={`planificacion-${contentPlanning.title()}.pdf`}
      >
        <TitleWithActions
          useMarkdown={true}
          title={contentPlanning.title() || contentPlanning.subject()}
        >
          <ContentPlanningVersionTag version={contentPlanning.appVersion()} />
        </TitleWithActions>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Generada el: {contentPlanning.creationDate()}
        </Typography>
        <ParametersBox parameters={parameters} />
        <Typography variant="h6" gutterBottom>
          Contenido generado:
        </Typography>
        {children}
      </PdfExportableContainer>
    </Box>
  );
}

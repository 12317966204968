import GeneratedContentPlanning from "../../app/GeneratedContentPlanning";

export default class ContentPlanningAdapter {
  constructor(contentPlanning) {
    this._contentPlanning = contentPlanning;
  }

  adapt() {
    return new GeneratedContentPlanning(
      this._contentPlanning.id,
      this._contentPlanning.grade,
      this._contentPlanning.instruction,
      this._contentPlanning.region,
      this._contentPlanning.subject,
      this._contentPlanning.content,
      this._contentPlanning.created_at,
      this._contentPlanning.topic,
      this._contentPlanning.time_unit,
      this._contentPlanning.app_version,
      this._contentPlanning.context_files_names,
      this._contentPlanning.public_id,
      this._contentPlanning.title,
    );
  }
}

import { Alert, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import Select from '../fields/Select';
import Text from '../fields/Text';
import FramedContainer from '../framed-container/FramedContainer';
import TextArea from '../fields/TextArea';
import { useApplication } from '../../providers/applicationProvider';
import FilledTextButton from '../buttons/FilledTextButton';
import ContentPlanningParameters from '../../app/ContentPlanningParameters';

export default function ContentPlanningForm ({ onContentPlanningChange }) {
  const regionOptions = ['CABA'];

  const [isGeneratingContentPlanning, setGeneratingContentPlanning] =
    useState(false);
  const [title, setTitle] = useState('');
  const [subject, setSubject] = useState('');
  const [grade, setGrade] = useState('');
  const [region, setRegion] = useState(regionOptions[0]);
  const [instruction, setInstruction] = useState('');
  const [topic, setTopic] = useState('');
  const [extension, setExtension] = useState('');
  const [formError, setFormError] = useState(false);

  const application = useApplication();
  const style = styles();

  const onClick = () => {
    setGeneratingContentPlanning(true);
    const contentPlanningParameters = new ContentPlanningParameters(
      subject,
      grade,
      region,
      instruction,
      topic,
      extension,
      title,
    );
    application
      .apiClient()
      .generateContentPlanning(contentPlanningParameters)
      .then((response) => {
        setFormError(false);
        onContentPlanningChange(response.contentPlanning());
        setGeneratingContentPlanning(false);
      })
      .catch(() => {
        setFormError(true);
        onContentPlanningChange();
        setGeneratingContentPlanning(false);
      });
  };

  const updateField = (setField) => (value) => setField(value);

  const updateSelectField = (setField) => (selectedValue) => {
    setField(selectedValue);
  };

  const renderError = () => {
    return formError ? (
      <Alert severity="error" sx={{ marginTop: '15px' }}>
        Ocurrió un error al generar la respuesta.
      </Alert>
    ) : undefined;
  };

  const isSubmitButtonEnabled =
    subject &&
    grade &&
    region &&
    topic &&
    extension &&
    !isGeneratingContentPlanning;

  const gradeOptions = [
    'Primer grado',
    'Segundo grado',
    'Tercer grado',
    'Cuarto grado',
    'Quinto grado',
    'Sexto grado',
    'Séptimo grado',
  ];

  const subjectOptions = ['Matemática', 'Lengua'];

  const extensionOptions = [
    'Entre 1 y 4 clases',
    'Entre 5 y 9 clases',
    'Entre 10 y 15 clases',
  ];

  return (
    <FramedContainer>
      <Box sx={style.mainContainer}>
        <Grid container rowSpacing="10px" columnSpacing="10px">
          <Grid size={12}>
            <Text
              id="title"
              label="Título"
              value={title}
              placeholder={
                '¿Tenés un nombre para la actividad que estás pensando?'
              }
              onChange={updateField(setTitle)}
              required={false}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 2 }}>
            <Select
              id="region"
              label="Región"
              placeholder="Seleccioná una región"
              value={region}
              onChange={updateSelectField(setRegion)}
              options={regionOptions}
              required
            />
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <Select
              id="grade"
              label="Grado"
              placeholder="Seleccioná un grado"
              value={grade}
              onChange={updateSelectField(setGrade)}
              options={gradeOptions}
              required
            />
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <Select
              id="subject"
              label="Materia"
              placeholder="Seleccioná una materia"
              value={subject}
              onChange={updateSelectField(setSubject)}
              options={subjectOptions}
              required
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Select
              id="extension"
              label="Extensión"
              value={extension}
              onChange={updateSelectField(setExtension)}
              placeholder="¿En cuantos encuentros se pretende ejecutar esta planificación?"
              options={extensionOptions}
              required
              fieldInfo="Cada unidad de tiempo es una sesión de 45 minutos"
            />
          </Grid>

          <Grid size={12}>
            <Text
              id="topic"
              label="Tema"
              value={topic}
              onChange={updateField(setTopic)}
              placeholder="Contanos sobre qué te gustaría trabajar"
              required
            />
          </Grid>

          <Grid size={12}>
            <TextArea
              id="instruction"
              label="Contexto"
              value={instruction}
              onChange={updateField(setInstruction)}
              placeholder="Ejemplo: Con cuantos estudiantes vas a trabajar, si hay necesidades particulares del grupo, qué tema vieron antes, etc."
              fieldInfo={
                'Indica que esperas de la planificación y cualquier información adicional que consideres relevante sobre el tema o el curso.'
              }
            />
          </Grid>
        </Grid>

        <Box>
          <FilledTextButton
            onClick={onClick}
            text={!isGeneratingContentPlanning ? 'Generar' : 'Generando...'}
            disabled={!isSubmitButtonEnabled}
          />

          {renderError()}
        </Box>
      </Box>
    </FramedContainer>
  );
}

const styles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    backgroundColor: 'white',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1.5rem',
    background: 'white',
  },
  formRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '10px',
  },
});

import GeneratedContentPlanning from "./generated-content-planning/GeneratedContentPlanning";
import React from "react";
import { getUrlForYearlyContentPlanningWith } from "../utils";
import SeeGeneratedContentPlanningWithPromptWrapper from "./generated-content-planning/SeeGeneratedContentPlanningWithPromptWrapper";

export default function SeeYearlyContentPlanningAndPrompt({
  yearlyContentPlanning,
}) {
  const parameters = {
    Región: yearlyContentPlanning.region(),
    Grado: yearlyContentPlanning.grade(),
    Contexto: yearlyContentPlanning.context(),
  };

  return (
    <SeeGeneratedContentPlanningWithPromptWrapper
      contentPlanning={yearlyContentPlanning}
      parameters={parameters}
    >
      <GeneratedContentPlanning
        contentPlanning={yearlyContentPlanning}
        readOnly
        editable={false}
        getPublicUrlFor={(publicId) =>
          getUrlForYearlyContentPlanningWith(publicId)
        }
      />
    </SeeGeneratedContentPlanningWithPromptWrapper>
  );
}

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";
import FramedContainer from "../framed-container/FramedContainer";
import React, { useRef, useState } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import TabPanel from "../panels/TabPanel";
import successToast from "../toasts/SuccessToast";
import { ContentPlanningPublicId } from "../buttons/ContentPlanningPublicId";
import FeedbackModal from "../modals/FeedbackModal";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClipboard } from "../../utils";
import errorToast from "../toasts/ErrorToast";
import FilledIconButton from "../buttons/FilledIconButton";
import "@mdxeditor/editor/style.css";
import ContentPlanningEditor from "../editor/ContentPlanningEditor";
import ContentPlanningEditableTitle from "../ContentPlanningEditableTitle";
import TitleWithActions from "../TitleWithActions";
import Markdown from "../Markdown";
import ContentPlanningRegenerationForm from "../forms/ContentPlanningRegenerationForm";

export default function GeneratedContentPlanning({
  contentPlanning,
  readOnly,
  editable,
  getPublicUrlFor,
  onContentPlanningChange,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [wasFeedbackSubmitted, setWasFeedbackSubmitted] = useState(false);

  const markdownRef = useRef();

  const isContentPlanningDefined = () => contentPlanning !== undefined;
  const style = styles();

  const renderFeedbackForm = () => {
    if (!readOnly) {
      return (
        <FeedbackModal
          contentPlanningId={contentPlanning.id()}
          onFeedbackSubmitted={() => {
            setWasFeedbackSubmitted(true);
          }}
          wasFeedbackSubmitted={wasFeedbackSubmitted}
        />
      );
    }
    return <></>;
  };

  const renderContextFiles = () => {
    if (contentPlanning.hasContextFiles()) {
      const contextFiles = contentPlanning.contextFiles();
      return contextFiles.length === 0 ? (
        "No se utilizaron documentos para generar el contenido."
      ) : (
        <List>
          {contextFiles.map((source) => (
            <ListItem key={source}>
              <ListItemIcon>
                <TextSnippetIcon />
              </ListItemIcon>
              <ListItemText primary={source} />
            </ListItem>
          ))}
        </List>
      );
    }
  };

  const onContentPlanningRegenerated = (contentPlanning) => {
    onContentPlanningChange(contentPlanning);
    setWasFeedbackSubmitted(false);
    successToast("Planificación regenerada.");
  };

  const actionButtons = () => {
    return (
      <Box sx={style.rowContainer}>
        <FilledIconButton
          icon={ShareIcon}
          label={"Compartir url"}
          onClick={() =>
            copyToClipboard(
              getPublicUrlFor(contentPlanning.publicId()),
              () => successToast("Url copiada"),
              () => errorToast("No se pudo copiar la url."),
            )
          }
        />
        <FilledIconButton
          icon={ContentCopyIcon}
          label={"Copiar planificación"}
          onClick={() =>
            copyToClipboard(
              `${contentPlanning.title()} \n\n${markdownRef.current.innerText}`,
              () => successToast("Planificación copiada"),
              () => errorToast("No se pudo copiar la planificación."),
            )
          }
        />
      </Box>
    );
  };

  const renderTitle = () => {
    return editable ? (
      <ContentPlanningEditableTitle
        key={`editable-title-${contentPlanning.id()}`}
        contentPlanning={contentPlanning}
        useMarkdown
        onContentPlanningChange={onContentPlanningChange}
      />
    ) : (
      <TitleWithActions title={contentPlanning.title()} useMarkdown={true} />
    );
  };

  const renderContent = () => {
    return (
      <Box ref={markdownRef}>
        {editable ? (
          <ContentPlanningEditor
            key={`editable-content-${contentPlanning.id()}`}
            contentPlanning={contentPlanning}
            onContentPlanningChange={onContentPlanningChange}
          />
        ) : (
          <Markdown content={contentPlanning.content()}></Markdown>
        )}
      </Box>
    );
  };

  const renderContentPlanning = () => (
    <FramedContainer>
      {renderTitle()}

      <Box sx={style.rowContainer}>
        <ContentPlanningPublicId publicId={contentPlanning.publicId()} />
        {actionButtons()}
      </Box>

      {renderContent()}

      <Box sx={{ paddingTop: "20px" }}>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          aria-label="Detalle del contenido generado"
        >
          <Tab label="Documentos contexto" />
          {!readOnly && <Tab label="Regeneración" />}
        </Tabs>
      </Box>

      <TabPanel selectedIndex={selectedTab} tabIndex={0}>
        {renderContextFiles()}
      </TabPanel>
      {!readOnly && (
        <TabPanel selectedIndex={selectedTab} tabIndex={1}>
          <ContentPlanningRegenerationForm
              contentPlanningToRegenerate={contentPlanning}
            onContentPlanningRegenerated={onContentPlanningRegenerated}
            isYearlyContentPlanning={false}
          />
        </TabPanel>
      )}
      {renderFeedbackForm()}
    </FramedContainer>
  );

  return isContentPlanningDefined() ? renderContentPlanning() : undefined;
}

const styles = () => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
  },
});
